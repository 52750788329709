import React from "react";
import tw, { styled } from "twin.macro";
import Page from "../components/Page";
import ProjectCard from "../components/ProjectCard";
import projects from "../data/projects";

const TitleSection = tw.div`
  text-center mx-4
`;

const Title = tw.div`
text-4xl font-bold mt-6 
`;

const Description = tw.p`
  mt-2
`;

const ProjectContainer = styled.div`
  ${tw`grid mx-4 mt-12 auto-rows-fr sm:grid-cols-2 xl:grid-cols-3 lg:mx-6 gap-y-10 xl:mx-auto`}
  max-width: 1920px;
`;

const ProjectsPage = ({ location }) => {
  return (
    <Page location={location}>
      <TitleSection>
        <Title>Previous Projects</Title>
        <Description>
          These are a combination of personal and notable school projects that I
          have completed in the past
        </Description>
      </TitleSection>
      <ProjectContainer>
        {projects.map((project) => (
          <div key={project.key} className="mt-2 mx-2">
            <ProjectCard project={project} />
          </div>
        ))}
      </ProjectContainer>
    </Page>
  );
};

export default ProjectsPage;
