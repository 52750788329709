import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";

const Container = styled(motion.div)`
  ${tw`rounded-lg shadow-lg flex h-full w-full flex-col md:flex-row `}
`;

const ImageContainer = styled.div`
  ${tw`rounded-lg h-2/3 sm:h-full md:w-1/2 lg:w-1/3`}
  @media(min-width: 768px) {
    height: 285px;
  }

  @media (min-width: 1024px) {
    height: 185px;
  }
  @media (min-width: 1280px) {
    height: 216px;
  }
  @media (min-width: 1536px) {
    height: 185px;
  }
`;

const ProjectInfoContainer = tw.div`
p-4 flex flex-col justify-between md:w-1/2 lg:w-2/3
`;

const Info = styled.div`
  & > h1 {
    ${tw`text-gray-900 font-bold text-2xl`};
  }

  & > p {
    ${tw`mt-2 text-gray-600 text-sm`}
  }
`;

const ProjectLink = styled.p`
  ${tw`text-gray-600 text-sm underline cursor-pointer text-blue-500`}
`;

const StyledImage = styled.img`
  ${tw`w-full h-full rounded-lg`}
`;

const ProjectCard = ({ project }) => {
  const { name, link, description, image } = project;
  return (
    <Container
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.7 },
      }}
    >
      <ImageContainer>
        <StyledImage
          src={image}
          className="w-full h-full rounded-lg"
          style={{ maxHeight: "380px" }}
        />
      </ImageContainer>
      <ProjectInfoContainer>
        <Info>
          <h1>{name}</h1>
          <p>{description}</p>
        </Info>
        <a href={link}>
          <ProjectLink>Go to project</ProjectLink>
        </a>
      </ProjectInfoContainer>
    </Container>
  );
};

export default ProjectCard;
